// Spireon colors

// primary

$light-primary: #343e48;
$light-primary-contrast: #ffffff;

$dark-primary: #343e48;
$dark-primary-contrast: #ffffff;

// accent/secondary

$light-accent: #007aff;
$light-accent-contrast: #ffffff;

$dark-accent: #007aff;
$dark-accent-contrast: #ffffff;

// common

$page-background: #f5f5f5;
